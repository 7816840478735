.work-container {
  padding-top: 40px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: lightgrey;
}

.work-info {
  display: contents;
  width: 100%;
}

.work-section {
  width: 100%;
}
