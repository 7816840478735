body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: "Roboto", sans-serif;
}

.home-container {
  width: 100vw;
  padding: 0;
  flex: none;
}

.hero-section {
  height: fit-content;
  min-height: 300px;
  padding-top: 20px;
  padding-bottom: 20px;

  overflow: hidden;
  /* background-color: #7cb342; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

@media (min-width: 768px) {
  .hero-section {
    flex-direction: row;
    justify-content: space-around;
  }
}

.avatar-container {
  z-index: 10;
}

.avatar-img {
  height: 300px;
  width: 300px;
  border-radius: 50%;
  background-color: rgb(86, 154, 243);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.details-container {
  text-align: center;
}

@media (min-width: 768px) {
  .details-container {
    margin-left: 20px;
    margin-top: 0;
  }
}

.title {
  margin: 10px 0;
  color: white;
}
.about-container {
  margin-top: 0px;
  display: flex;
  z-index: 2;
  color: white;
  background-color: rgb(86, 154, 243);
  padding-bottom: 25px;
}

.info-section {
  padding: 20px;
  height: fit-content;
  justify-content: center;
  display: flex;
  text-align: center;
  border-radius: 5px;

  color: white;
}

.info-card {
  margin-bottom: 20px;
  background-color: none;
  color: white;
}

.info-button {
  display: block;
  margin: 20px auto;
  background-color: #4b830d;
  color: white;
}

.about-section {
  justify-content: center;
  color: white;
}

.about-info {
  margin-top: 20px;
  margin-right: 20px;
  display: flex;
  color: white;
}
.about-work {
  display: block;
  color: white;
}

.availability {
  font-style: italic;
  color: white;
}

.info-card-content {
  background-color: rgba(240, 240, 240, 0.8);
  backdrop-filter: blur(10px);
  color: black;
  padding: 10px;
  margin-bottom: 0;
}

.info-card-title {
  text-align: center;
  background-color: rgba(47, 79, 79, 0.8);
  backdrop-filter: blur(10px);
  color: white;
  padding: 10px;
  margin-bottom: 0;
}

.parallax-section {
  background-color: black;
  text-align: center;
  color: white;
  padding: 90px 0;
  width: 100%;
}

.parallax-button {
  background-color: #4b830d;
  color: white;
}

footer {
  height: 50px;
  z-index: 5;
  bottom: 0;
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 20px 0;
  width: 100%;
}

footer ul {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
}

footer li {
  margin: 0 10px;
}

footer a {
  color: white;
  text-decoration: none;
}
