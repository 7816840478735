html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: "Roboto", sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header,
.footer {
  background-color: #1976d2;
  color: white;
}

main {
  flex: 1;
  width: 100%;
}

.footer {
  padding: 1rem 0;
  text-align: center;
  width: 100%;
  color: white;
  background-color: black;
}

.container {
  margin: 0;
  padding: 0;
  display: contents;
  width: 100%;
}

ul {
  list-style-type: none;
  padding: 0;
}
